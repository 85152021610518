<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>
          {{ i18n("Custom.PersonalTestResultInquiry") }}
      </span>
    </div>

    <vue-bootstrap4-table
      :rows="dataList"
      :columns="columns"
      :config="config"
      :classes="classes"
    >
      <template slot="func" slot-scope="props">
        <!-- <button
          class="btn btn-sm btn-blue mr-2"
          @click.prevent="openDetailModal(props.row)"
        >
          {{ i18n("Basic.View") }}
        </button> -->

        <button
          class="btn btn-sm btn-blue mr-2"
          @click.prevent="openPatternModal(props.row)"
        >
          {{ i18n("Custom.ViewCorrespondingTemplate") }}
        </button>
      </template>
    </vue-bootstrap4-table>

    <!-- 測驗結果 modal -->
    <div class="modal fade" id="detailModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <el-form
          :model="detailModal"
          label-position="top"
          ref="detailModal"
          class="modal-content"
        >
          <div class="modal-header">{{ i18n("Result.TestResult") }}</div>
          <div class="modal-body">
            <table class="info-table">
              <tr>
                <th>{{ i18n("Custom.CompanyName") }}</th>
                <td>{{ detailModal.CompanyName }}</td>
                <th>{{ i18n("Basic.Department") }}</th>
                <td>{{ detailModal.DepartmentName }}</td>
              </tr>
              <tr>
                <th>{{ i18n("Custom.JobNumber") }}</th>
                <td>{{ detailModal.EmpNo }}</td>
                <th>{{ i18n("Release.EmployeeName") }}</th>
                <td>{{ detailModal.EmpCName }}</td>
              </tr>
              <tr>
                <th>{{ i18n("Custom.QuizPostName") }}</th>
                <td>{{ detailModal.ReleaseName }}</td>
                <th>{{ i18n("Custom.QuizDate") }}</th>
                <td>{{ detailModal.FinishTime }}</td>
              </tr>
              <tr>
                <th>{{ i18n("Custom.JobTitle") }}</th>
                <td colspan="3">{{ detailModal.TitleName }}</td>
              </tr>
            </table>

            <table class="table table-striped table-light table-hover">
              <thead>
                <tr>
                  <th style="vertical-align: middle; width: 70px; text-align: center">
                    {{ i18n("Custom.QuestionNumber") }}
                  </th>
                  <th style="vertical-align: middle">{{ i18n("Custom.Topic") }}</th>
                  <th style="vertical-align: middle">
                    {{ i18n("Custom.SubjectOptions") }}
                  </th>
                  <th style="vertical-align: middle">
                    {{ i18n("Custom.CorrespondingPersonalityTraits") }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="Question in detailModal.ResultAnswers" :key="Question.SeqNo">
                  <td style="text-align: center">{{ Question.SeqNo }}</td>
                  <td>{{ Question.Question }}</td>
                  <td>{{ Question.Option }}</td>
                  <td style="text-align: center">{{ Question.Personality }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Basic.Close") }}
            </button>
          </div>
        </el-form>
      </div>
    </div>



    <el-dialog :title="i18n('Custom.CorrespondingTemplate')" :visible.sync="dialogVisible" width="80%"
      top="40px">
     <TraitComponent :patternModal="patternModal" />
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="dialogVisible = false">{{ i18n("Basic.Close") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TraitComponent from "@/components/Result/TraitComponent";
import $ from "jquery";

export default {
  data() {
    return {
      dialogVisible:false,
      screen: null, // loading遮罩
      detailModal: {},
      patternModal: {}, //匹配模板modal
      config: {
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 10,
        per_page_options: [10, 25, 50],
        global_search: {
          visibility: false,
        },
        // checkbox_rows: true,
      },
      dataList: [],

      classes: {
        table: "table-light table-striped",
        // via: 'table-danger'
      },
    };
  },
  components: {
    TraitComponent
  },
  computed: {
    columns() {
      const col = [
        {
          label: this.i18n("Release.QuizNumber"),
          name: "SeqNo",
          sort: true,
        },
        {
          label: this.i18n("Release.QuizPostName"),
          name: "ReleaseName",
          sort: true,
        },
        {
          label: this.i18n("Custom.Year"),
          name: "Year",
          sort: true,
        },
        {
          label: this.i18n("Custom.Function"),
          name: "func",
          slot_name: "func",
        },
      ];
      return col;
    },
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    getDataList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Self`;

      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.dataList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    openDetailModal(row) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Self/${row.EvaluationPersonGuid}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.detailModal = {
              ...response.data.Data,
            };
            $("#detailModal").modal("show");
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    // 檢視對應模板
    openPatternModal(row) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Self/Pattern/${row.EvaluationPersonGuid}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.patternModal = {
              ...response.data.Data,
            };

            this.dialogVisible=true;
            // $("#patternModal").modal("show");
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
  },
  created() {
    this.getDataList();
  },
};
</script>

